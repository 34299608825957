import { SiLinkedin, SiGithub, SiInstagram, SiTwitter } from 'react-icons/si';
import styled from 'styled-components';

const AppFooterLight = styled.div`
  border-top: 1px solid var(--color-bar-dark);
  background: var(--color-bg-light);
  height: 150px;
  padding: 0 4rem;
  width: 100%;
`;

const FooterContentLight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: var(--color-bar-dark);
  font-family: var(--font-base);

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  };
`;

const FooterCopyrightLight = styled.div`
  color: var(--color-gray1)

  @media (max-width: 600px) {
    margin-bottom: 1.5rem;
  }
`;

const FooterCopyrightBoldLight = styled.a`
  color: var(--color-black);
`;

const FooterSocialIconLight = styled.div`
  font-size: 1.4rem;
  width: auto;
  margin: 0 0.45rem;
`;

const FooterSocialLinkLight = styled.a``;

const FooterSocialLight = styled.div`
  display: flex;
  align-items: center;
`;

const FooterLight = () => (
  <AppFooterLight>
    <FooterContentLight>
      <FooterCopyrightLight>
        Made by <FooterCopyrightBoldLight href="#home">Laurenz Odenbreit</FooterCopyrightBoldLight> - Copyright 2022
      </FooterCopyrightLight>
      <FooterSocialLight>
        <FooterSocialLinkLight href="https://www.linkedin.com/in/laurenz-odenbreit-437305223/" target="_blank">
          <FooterSocialIconLight><SiLinkedin/></FooterSocialIconLight>
        </FooterSocialLinkLight>
        <FooterSocialLinkLight href="https://github.com/laurenzodenbreit" target="_blank">
          <FooterSocialIconLight><SiGithub/></FooterSocialIconLight>
        </FooterSocialLinkLight>
        <FooterSocialLinkLight href="https://www.instagram.com/laurenzmaximilian/" target="_blank">
          <FooterSocialIconLight><SiInstagram/></FooterSocialIconLight>
        </FooterSocialLinkLight>
        <FooterSocialLinkLight href="https://twitter.com/justlaurenz" target="_blank">
          <FooterSocialIconLight><SiTwitter/></FooterSocialIconLight>
        </FooterSocialLinkLight>
      </FooterSocialLight>
    </FooterContentLight>
  </AppFooterLight>
)

export default FooterLight;
import { HiOutlineMail } from 'react-icons/hi';
import styled from 'styled-components';

const FindUsHeaderLight = styled.h2`
  color: var(--color-bg-dark);
  font-family: var(--font-base);
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 1.5rem;
`;

const FindUsContentLight = styled.div`
  color: var(--color-bg-dark);
  font-size: 1.5rem;
  max-width: 80%;
  text-align: center;
`;

const MailButtonLight = styled.button`
  background-color: var(--color-button);
  color: var(--color-white);
  font-family: var(--font-base);
  font-weight: 400;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  padding: 0.5rem 2rem;
  border-radius: 0.2rem;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AppFindUsLight = styled.div`
  height: auto;
  width: 100%;
  background: var(--color-bg-light);
  color: var(--color-bg-dark);
  font-family: var(--font-base);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10rem;
`;

const AppFindUsMailLight = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

const FindUsLight = () => (
  <AppFindUsLight id='findus'>
    <FindUsHeaderLight>Contact</FindUsHeaderLight>
    <FindUsContentLight>
    Feel free to contact me via email by clicking the button below.
    </FindUsContentLight>
    <MailButtonLight style={{ marginTop: '2rem' }} onClick={() => window.location = 'mailto:laurenz@odenbreit.com'}>
        <AppFindUsMailLight><HiOutlineMail/></AppFindUsMailLight>
        laurenz@odenbreit.com
    </MailButtonLight>
  </AppFindUsLight>
)

export default FindUsLight;
import './LandingPageLight.css'; 
import images from '../../constants/images';

const LandingPageLight= () => (
  <div className='app__landingpagelight' id='home'>
    <div className='app__landingpage-contentlight'>
      <p className='app__landingpage-introductionlight'>
        Hi, I am Laurenz
      </p>
      <p className='app__landingpage-introductionlight'>
        A Webdeveloper <br></br>based in Berlin.
      </p>
      <p className='app__landingpage-subtextlight'>
        I plan to build webprojects for businesses and companies to create a seamless interaction between business and customer.
      </p>
    </div>
    <div className='app__landingpage-picturelight'>
      <div className='app__landingpage-picture_framelight'>
        <a href="#findus">
          <img src={images.profile} alt="profile" />
        </a>
      </div>
    </div>
  </div>
)

export default LandingPageLight;
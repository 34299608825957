import images from "../../constants/images";
import { BsArrowDown } from 'react-icons/bs';

import './WorkLight.css'

const WorkLight = () => (
  <div className="app__worklight" id="work">
    <div className="app__work-imglight">
      <div className="app__work-titlelight">
        <BsArrowDown className="app__work-arrow__bslight" />
        PERSONAL PROJECTS
      </div>
      <div className="app__work-centerlight">
        <img src={images.bsp1} alt="bsp1"/>
        {/*<div className="app__work-overlay" />*/}
      </div>
      
      {/* <img src={images.bsp2} alt="bsp2" className="app__workimg2"/> */}
      <div className="app__work-cominglight">MORE PROJECTS WILL SHOW UP HERE LATER</div>
    </div>
  </div>
)

export default WorkLight;
import { SiLinkedin, SiGithub, SiInstagram, SiTwitter } from 'react-icons/si';
import styled from 'styled-components';

const AppFooterDark = styled.div`
  border-top: 1px solid var(--color-gray1);
  background: var(--color-bg-dark);
  height: 150px;
  padding: 0 4rem;
  width: 100%;
`;

const FooterContentDark = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: var(--color-gray2);
  font-family: var(--font-base);

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  };
`;

const FooterCopyrightDark = styled.div`
  @media (max-width: 600px) {
    margin-bottom: 1.5rem;
  }
`;

const FooterCopyrightBoldDark = styled.a`
  color: var(--color-gray1);
`;

const FooterSocialIconDark = styled.div`
  font-size: 1.4rem;
  width: auto;
  margin: 0 0.45rem;
`;

const FooterSocialLinkDark = styled.a``;

const FooterSocialDark = styled.div`
  display: flex;
  align-items: center;
`;

const FooterDark = () => (
  <AppFooterDark>
    <FooterContentDark>
      <FooterCopyrightDark>
        Made by <FooterCopyrightBoldDark className="app__footer-content_bold" href="#home">Laurenz Odenbreit</FooterCopyrightBoldDark> - Copyright 2022
      </FooterCopyrightDark>
      <FooterSocialDark>
        <FooterSocialLinkDark href="https://www.linkedin.com/in/laurenz-odenbreit-437305223/" target="_blank">
          <FooterSocialIconDark><SiLinkedin/></FooterSocialIconDark>
        </FooterSocialLinkDark>
        <FooterSocialLinkDark href="https://github.com/laurenzodenbreit" target="_blank">
          <FooterSocialIconDark><SiGithub/></FooterSocialIconDark>
        </FooterSocialLinkDark>
        <FooterSocialLinkDark href="https://www.instagram.com/laurenzmaximilian/" target="_blank">
          <FooterSocialIconDark><SiInstagram/></FooterSocialIconDark>
        </FooterSocialLinkDark>
        <FooterSocialLinkDark href="https://twitter.com/justlaurenz" target="_blank">
          <FooterSocialIconDark><SiTwitter/></FooterSocialIconDark>
        </FooterSocialLinkDark>
      </FooterSocialDark>
    </FooterContentDark>
  </AppFooterDark>
)

export default FooterDark;
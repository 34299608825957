import { IoDocumentTextOutline } from 'react-icons/io5';
import pdf from '../../assets/resume.pdf';
import styled from 'styled-components';

const AboutHeaderDark = styled.h2`
  color: var(--color-gray1);
  font-family: var(--font-base);
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 1.5rem;
`;

const AboutContentDark = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
  margin: 2.2rem 0;
  font-size: 1.5rem;
  color: var(--color-white);
`;

const AppResumeTitleDark = styled.div``;

const AppResumeLinkDark = styled.a`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: 1900px) {
    font-size: 1.75rem;
  }
  `;

const AboutTextDark = styled.div`
  width: 50%;
  font-size: 1.5rem;
  text-align: center;
  text-align-last: none;

  @media (min-width: 1900px) {
    font-size: 2rem;
  }

  @media (max-width: 650px) {
    width: 80%;
  }
`;

const AppAboutDark = styled.div`
  background-color: var(--color-bg-dark);
  color: var(--color-gray2);
  height: auto;
  font-family: var(--font-base);
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14rem;
  width: 100%;

  @media (min-width: 1900px) {
    padding-top: 7.5rem;
    padding-bottom: 17.5rem;
  }

  @media (max-width: 650px) {
    padding-bottom: 8rem;
  }
`;

const AboutResumeIconContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

const AboutDark = () => (
  <AppAboutDark className="app__about" id="about">
    <AboutHeaderDark>About Me</AboutHeaderDark>
      <AboutTextDark>
        I am a nineteen year old self taught web-developer. I started my coding-journey in late 2021. My skills include HTML, CSS and react.js / Java Script.
        <br /><br />
        I am currently living in berlin and attending the free university of berlin for law school for my first semester. 
      </AboutTextDark>
    <AboutContentDark>
      <AppResumeTitleDark>
        <AppResumeLinkDark href={pdf} target="_blank" rel='noreferrer' className='app__about-resume__link'>
          RESUME
          <AboutResumeIconContainer>
            <IoDocumentTextOutline />
          </AboutResumeIconContainer>
        </AppResumeLinkDark>
      </AppResumeTitleDark>
    </AboutContentDark>
  </AppAboutDark>
);

export default AboutDark;
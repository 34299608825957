import images from "../../constants/images";
import { BsArrowDown } from 'react-icons/bs';

import './WorkDark.css'

const WorkDark = () => (
  <div className="app__work" id="work">
    <div className="app__work-img">
      <div className="app__work-title">
        <BsArrowDown className="app__work-arrow__bs" />
        PERSONAL PROJECTS
      </div>
      <div className="app__work-center">
        <img src={images.bsp1} alt="bsp1"/>
        {/*<div className="app__work-overlay" />*/}
      </div>
      
      {/* <img src={images.bsp2} alt="bsp2" className="app__workimg2"/> */}
      <div className="app__work-coming">MORE PROJECTS WILL SHOW UP HERE LATER</div>
    </div>
  </div>
)

export default WorkDark;
import styled from "styled-components";

const AppNavbarDark = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  color: var(--color-bg-light);
  background-color: var(--color-bar-dark);
  padding-right: 3.5rem;
`;

const NavbarCompanyDark = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
`;

const AppNavbarSpacerDark = styled.div`
  background-color: var(--color-bg-light);
  height: 1.5rem;
  width: 2px;
  margin-right: 0.1rem;

  @media (max-width: 768px) {
    position: absolute;
    visibility: hidden;
  }
`;

const NavLinkDark = styled.a`
  padding: 0 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    position: absolute;
    visibility: hidden;
  }
`;

const NavbarLinkContainerDark = styled.div`
  display: flex;
  align-items: center;
`;

function NavbarDark()  {

  return (
    <AppNavbarDark>
      <NavbarCompanyDark>odenbreit</NavbarCompanyDark>
      <NavbarLinkContainerDark>
        <NavLinkDark href="#home">HOME</NavLinkDark>
        <NavLinkDark href="#work">WORK</NavLinkDark>
        <NavLinkDark href="#about">ABOUT</NavLinkDark>
        <NavLinkDark href="#contact">CONTACT</NavLinkDark>
        <AppNavbarSpacerDark />
      </NavbarLinkContainerDark>
    </AppNavbarDark>
  )
};

export default NavbarDark;
import './AboutLight.css';
import { IoDocumentTextOutline } from 'react-icons/io5';
import pdf from '../../assets/resume.pdf';
import styled from 'styled-components';

const AboutHeaderLight = styled.h2`
  color: var(--color-bg-dark);
  font-family: var(--font-base);
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 1.5rem;
`;

const AboutContentLight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
  margin: 2.2rem 0;
  font-size: 1.5rem;
  color: var(--color-black);
`;

const AppResumeTitleLight = styled.div``;

const AppResumeLinkLight = styled.a`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AboutTextLight = styled.div`
  width: 50%;
  font-size: 1.5rem;
  text-align: center;
  text-align-last: none;
`;

const AppAboutLight = styled.div`
  background-color: var(--color-bg-light);
  color: var(--color-bg-dark);
  height: auto;
  font-family: var(--font-base);
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14rem;
  width: 100%;
`;

const AboutLight = () => (
  <AppAboutLight className="app__about" id="about">
    <AboutHeaderLight>About Me</AboutHeaderLight>
      <AboutTextLight>
        I am a nineteen year old self taught web-developer. I started my coding-journey in late 2021. My skills include HTML, CSS and react.js / Java Script.
        <br /><br />
        I am currently living in berlin and attending the free university of berlin for law school for my first semester. 
      </AboutTextLight>
    <AboutContentLight>
      <AppResumeTitleLight>
        <AppResumeLinkLight href={pdf} target="_blank" rel='noreferrer' className='app__about-resume__link'>
          RESUME
          <IoDocumentTextOutline className='app__about-resume__icon'/>
        </AppResumeLinkLight>
      </AppResumeTitleLight>
    </AboutContentLight>
  </AppAboutLight>
);

export default AboutLight;
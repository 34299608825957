import { useState } from "react";
import styled from "styled-components";

import { BsLightbulbFill, BsLightbulbOffFill } from "react-icons/bs";

import './App.css';

// import Navbar from "./components/Navbar/Navbar";
// import LandingPage from "./container/LandingPage/LandingPage";
// import Work from './container/Work/Work';
// import About from './container/About/About';
// import FindUs from './container/FindUs/FindUs';
// import Footer from "./components/Footer/Footer";

import NavbarDark from "./app__dark/components/NavbarDark/NavbarDark";
import LandingPageDark from './app__dark/container/LandingPageDark/LandingPageDark';
import WorkDark from './app__dark/container/WorkDark/WorkDark';
import AboutDark from './app__dark/container/AboutDark/AboutDark';
import FindUsDark from './app__dark/container/FindUsDark/FindUsDark';
import FooterDark from './app__dark/components/FooterDark/FooterDark';
import FooterLight from "./app__light/components/FooterLight/FooterLight";
import NavbarLight from "./app__light/components/NavbarLight/NavbarLight";
import AboutLight from "./app__light/container/AboutLight/AboutLight";
import FindUsLight from "./app__light/container/FindUsLight/FindUsLight";
import LandingPageLight from "./app__light/container/LandingPageLight/LandingPageLight";
import WorkLight from "./app__light/container/WorkLight/WorkLight";

function App() {
  const AppLogin = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--color-bg-dark);
  color: var(--color-bg-light);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.div`
  background-color: var(--color-bar-dark);
  color: var(--color-bg-light);
  padding: 3rem;
  border-radius: 0.5rem;  
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginHeader = styled.h2``;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UsernameInput = styled.input`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.2rem;  
`;

const PasswordInput = styled.input`
  font-size: 1rem;
  margin-bottom: 0.4rem;
`;

const SubmitButton = styled.input`
  font-size: 0.9rem;
  padding: 0.1rem 1rem;
  border-radius: 0.2rem;
  border: none;
  background-color: var(--color-bg-light);
`;

function Login() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const database = [ { username: "laurenz", password: "password" } ];

  const handleSubmit = (event) => {
    event.preventDefault();

    var { uname, pass } = document.forms[0];
    const userData = database.find((user) => user.username === uname.value);

    if (userData) {
      if (userData.password === pass.value) {
        setIsSubmitted(true);
      }
    };
  };

  const renderForm = (
    <LoginContainer>
      <LoginHeader>Login</LoginHeader>
      <LoginForm onSubmit={handleSubmit}>
          <UsernameInput type="text" name="uname" required/>        
          <PasswordInput type="password" name="pass" required/>
          <SubmitButton type='submit' value='LOGIN'/>
      </LoginForm>
    </LoginContainer>
  );

  return (
    <AppLogin>
        {isSubmitted ? setIsLoggedIn(true) : renderForm}
    </AppLogin>
  );
}
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isThemeDark, setIsThemeDark] = useState(true);

const ButtonTheme = styled.button`
  border: none;
  color: ${({ isThemeDark }) => isThemeDark ? 'var(--color-bg-light)' : 'var(--color-bg-dark)'};
  background-color: transparent;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const ThemeSwitchContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0.8rem;
`;

function ThemeSwitch() {

  return (
    <ButtonTheme isThemeDark={isThemeDark} 
    onClick={() => setIsThemeDark(!isThemeDark)}>
      {isThemeDark ? <BsLightbulbFill /> : <BsLightbulbOffFill />} 
    </ButtonTheme>
  )

}

  return (
    <div className="app__render" isLoggedIn={isLoggedIn}>
      {isLoggedIn ? 
      <div className="app">
        {isThemeDark ? 
        <div className="app__dark">
          <NavbarDark />
          <ThemeSwitchContainer>
            <ThemeSwitch/>
          </ThemeSwitchContainer>
          <LandingPageDark />
          <WorkDark />
          <AboutDark />
          <FindUsDark />
          <FooterDark />
        </div> : 
        <div className="app__light">
          <NavbarLight />
          <ThemeSwitchContainer>
            <ThemeSwitch />
          </ThemeSwitchContainer>
          <LandingPageLight />
          <WorkLight />
          <AboutLight />
          <FindUsLight />
          <FooterLight />
        </div>}
      </div> : 
      <Login/>
    }
  </div>
  );
}

export default App;
import './LandingPageDark.css'; 
import images from '../../constants/images';

const LandingPageDark= () => (
  <div className='app__landingpage' id='home'>
    <div className='app__landingpage-content'>
      <p className='app__landingpage-introduction'>
        Hi, I am Laurenz
      </p>
      <p className='app__landingpage-introduction'>
        A Webdeveloper <br></br>based in Berlin.
      </p>
      <p className='app__landingpage-subtext'>
        I plan to build webprojects for businesses and companies to create a seamless interaction between business and customer.
      </p>
    </div>
    <div className='app__landingpage-picture'>
      <div className='app__landingpage-picture_frame'>
        <a href="#findus">
          <img src={images.profile} alt="profile" />
        </a>
      </div>
    </div>
  </div>
)

export default LandingPageDark;
import styled from "styled-components";

const AppNavbarLight = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  color: var(--color-bg-dark);
  background-color: var(--color-bar-light);
  padding-right: 3.5rem;
`;

const NavbarCompanyLight = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
`;

const AppNavbarSpacerLight = styled.div`
  background-color:var(--color-bar-dark);
  height: 1.5rem;
  width: 2px;
  margin-right: 0.1rem;

  @media (max-width: 768px) {
    position: absolute;
    visibility: hidden;
  }
`;

const NavLinkLight = styled.a`
  padding: 0 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    position: absolute;
    visibility: hidden;
  }
`;

const NavbarLinkContainerLight = styled.div`
  display: flex;
  align-items: center;
`;

function NavbarLight()  {

  return (
    <AppNavbarLight>
      <NavbarCompanyLight>odenbreit</NavbarCompanyLight>
      <NavbarLinkContainerLight>
        <NavLinkLight href="#home">HOME</NavLinkLight>
        <NavLinkLight href="#work">WORK</NavLinkLight>
        <NavLinkLight href="#about">ABOUT</NavLinkLight>
        <NavLinkLight href="#contact">CONTACT</NavLinkLight>
        <AppNavbarSpacerLight/>
      </NavbarLinkContainerLight>
    </AppNavbarLight>
  )
};

export default NavbarLight;